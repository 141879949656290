/**
* Template Name: NiceAdmin - v2.3.1
* Template URL: https://bootstrapmade.com/nice-admin-bootstrap-admin-html-template/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# Fuentes Personalizadas
--------------------------------------------------------------*/

.emoji-1 {
  background-image: url('/emojis-svg/emoji-1.svg');
  width: 50px;
  height: 50px;
  display: inline-block;
}

.emoji-2 {
  background-image: url('/emojis-svg/emoji-2.svg');
  width: 50px;
  height: 50px;
  display: inline-block;
}

.emoji-3 {
  background-image: url('/emojis-svg/emoji-3.svg');
  width: 50px;
  height: 50px;
  display: inline-block;
}

.emoji-4 {
  background-image: url('/emojis-svg/emoji-4.svg');
  width: 50px;
  height: 50px;
  display: inline-block;
}

.emoji-5 {
  background-image: url('/emojis-svg/emoji-5.svg');
  width: 50px;
  height: 50px;
  display: inline-block;
}

.emoji-6 {
  background-image: url('/emojis-svg/emoji-6.svg');
  width: 50px;
  height: 50px;
  display: inline-block;
}

.emoji-7 {
  background-image: url('/emojis-svg/emoji-7.svg');
  width: 50px;
  height: 50px;
  display: inline-block;
}

.emoji-8 {
  background-image: url('/emojis-svg/emoji-8.svg');
  width: 50px;
  height: 50px;
  display: inline-block;
}

.emoji-9 {
  background-image: url('/emojis-svg/emoji-9.svg');
  width: 50px;
  height: 50px;
  display: inline-block;
}

.emoji-10 {
  background-image: url('/emojis-svg/emoji-10.svg');
  width: 50px;
  height: 50px;
  display: inline-block;
}

.emoji-11 {
  background-image: url('/emojis-svg/emoji-11.svg');
  width: 50px;
  height: 50px;
  display: inline-block;
}

.emoji-12 {
  background-image: url('/emojis-svg/emoji-12.svg');
  width: 50px;
  height: 50px;
  display: inline-block;
}

.emoji-13 {
  background-image: url('/emojis-svg/emoji-13.svg');
  width: 50px;
  height: 50px;
  display: inline-block;
}

.emoji-14 {
  background-image: url('/emojis-svg/emoji-14.svg');
  width: 50px;
  height: 50px;
  display: inline-block;
}

.emoji-15 {
  background-image: url('/emojis-svg/emoji-15.svg');
  width: 50px;
  height: 50px;
  display: inline-block;
}

.emoji-16 {
  background-image: url('/emojis-svg/emoji-16.svg');
  width: 50px;
  height: 50px;
  display: inline-block;
}

.emoji-17 {
  background-image: url('/emojis-svg/emoji-17.svg');
  width: 50px;
  height: 50px;
  display: inline-block;
}

.emoji-18 {
  background-image: url('/emojis-svg/emoji-18.svg');
  width: 50px;
  height: 50px;
  display: inline-block;
}

.emoji-19 {
  background-image: url('/emojis-svg/emoji-19.svg');
  width: 50px;
  height: 50px;
  display: inline-block;
}

.emoji-20 {
  background-image: url('/emojis-svg/emoji-20.svg');
  width: 50px;
  height: 50px;
  display: inline-block;
}

.emoji-21 {
  background-image: url('/emojis-svg/emoji-21.svg');
  width: 50px;
  height: 50px;
  display: inline-block;
}

.emoji-22 {
  background-image: url('/emojis-svg/emoji-22.svg');
  width: 50px;
  height: 50px;
  display: inline-block;
}

.emoji-23 {
  background-image: url('/emojis-svg/emoji-23.svg');
  width: 50px;
  height: 50px;
  display: inline-block;
}

.emoji-24 {
  background-image: url('/emojis-svg/emoji-24.svg');
  width: 50px;
  height: 50px;
  display: inline-block;
}

.emoji-25 {
  background-image: url('/emojis-svg/emoji-25.svg');
  width: 50px;
  height: 50px;
  display: inline-block;
}

.emoji-26 {
  background-image: url('/emojis-svg/emoji-26.svg');
  width: 50px;
  height: 50px;
  display: inline-block;
}

.emoji-27 {
  background-image: url('/emojis-svg/emoji-27.svg');
  width: 50px;
  height: 50px;
  display: inline-block;
}

.emoji-28 {
  background-image: url('/emojis-svg/emoji-28.svg');
  width: 50px;
  height: 50px;
  display: inline-block;
}

.emoji-29 {
  background-image: url('/emojis-svg/emoji-29.svg');
  width: 50px;
  height: 50px;
  display: inline-block;
}

.emoji-30 {
  background-image: url('/emojis-svg/emoji-30.svg');
  width: 50px;
  height: 50px;
  display: inline-block;
}

.emoji-31 {
  background-image: url('/emojis-svg/emoji-31.svg');
  width: 50px;
  height: 50px;
  display: inline-block;
}

.emoji-32 {
  background-image: url('/emojis-svg/emoji-32.svg');
  width: 50px;
  height: 50px;
  display: inline-block;
}

.emoji-33 {
  background-image: url('/emojis-svg/emoji-33.svg');
  width: 50px;
  height: 50px;
  display: inline-block;
  background-size: cover;
}

.emoji-34 {
  background-image: url('/emojis-svg/emoji-34.svg');
  width: 50px;
  height: 50px;
  display: inline-block;
  background-size: cover;
}

.emoji-35 {
  background-image: url('/emojis-svg/emoji-35.svg');
  width: 50px;
  height: 50px;
  display: inline-block;
  background-size: cover;
}

.emoji-36 {
  background-image: url('/emojis-svg/emoji-36.svg');
  width: 50px;
  height: 50px;
  display: inline-block;
  background-size: cover;
}

.emoji-37 {
  background-image: url('/emojis-svg/emoji-37.svg');
  width: 50px;
  height: 50px;
  display: inline-block;
  background-size: cover;
}

.emoji-38 {
  background-image: url('/emojis-svg/emoji-38.svg');
  width: 50px;
  height: 50px;
  display: inline-block;
  background-size: cover;
}

.emoji-39 {
  background-image: url('/emojis-svg/emoji-39.svg');
  width: 50px;
  height: 50px;
  display: inline-block;
  background-size: cover;
}

.emoji-40 {
  background-image: url('/emojis-svg/emoji-40.svg');
  width: 50px;
  height: 50px;
  display: inline-block;
  background-size: cover;
}

.emoji-41 {
  background-image: url('/emojis-svg/emoji-41.svg');
  width: 50px;
  height: 50px;
  display: inline-block;
  background-size: cover;
}

.emoji-42 {
  background-image: url('/emojis-svg/emoji-42.svg');
  width: 50px;
  height: 50px;
  display: inline-block;
  background-size: cover;
}

.emoji-43 {
  background-image: url('/emojis-svg/emoji-43.svg');
  width: 50px;
  height: 50px;
  display: inline-block;
  background-size: cover;
}

.emoji-44 {
  background-image: url('/emojis-svg/emoji-44.svg');
  width: 50px;
  height: 50px;
  display: inline-block;
  background-size: cover;
}

.emoji-45 {
  background-image: url('/emojis-svg/emoji-45.svg');
  width: 50px;
  height: 50px;
  display: inline-block;
  background-size: cover;
}

.emoji-46 {
  background-image: url('/emojis-svg/emoji-46.svg');
  width: 50px;
  height: 50px;
  display: inline-block;
  background-size: cover;
}

.emoji-47 {
  background-image: url('/emojis-svg/emoji-47.svg');
  width: 50px;
  height: 50px;
  display: inline-block;
  background-size: cover;
}


/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
:root {
  scroll-behavior: smooth;
}

body {
  font-family: "Open Sans", sans-serif;
  background: #def2ff;
  color: #000;
  font-size: 15px;
}

a {
  color: #000;
  text-decoration: none;
  font-weight: 600;
}

a:hover {
  color: #006eff;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Nunito", sans-serif;
}

.button,
.button-small {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 5px;
  color: #000;
  width: 100%;
  height: 100%;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid #d1f0ff;
  background-color: #d1f0ff;
  border-color: #a2c2e6;
  box-shadow: 0 0 3px 0 #66b9fb;
  transition: background-color .15s ease-in-out
}

.button:hover {
  outline: none;
  box-shadow: 0 0 4px 0 #007bdd;
  border: 1px solid #66b9fb;
}

.button-small {
  height: auto;
  width: 100%;
}

.form-control {
  padding: 10px;
  font-size: 18px !important;
}

.form-control:focus {
  border: 1px solid #66b9fb !important;
  box-shadow: 0 0 4px 0 #007bdd !important;
  background-color: #d1f0ff !important;
  box-shadow: unset;
}

.form-btn__perfil {
  display: flex;
  align-items: center;
  justify-content: end;
}

.form-btn__perfil .button {
  width: auto;
}

.btn-check:focus+.btn,
.btn:focus {
  box-shadow: unset;
}

.btn-check:active+.btn:focus,
.btn-check:checked+.btn:focus,
.btn.active:focus,
.btn.show:focus,
.btn:active:focus {
  box-shadow: unset;
}

#search-results {
  background-color: #d1f0ff;
  border-color: #a2c2e6;
  box-shadow: 0 0 3px 0 #66b9fb;
}

#search-results a:hover {
  background-color: #71b5eb;
  color: #000;
}

#search-results p {
  margin-bottom: 0px !important;
  padding: 10px;
}

.list-group-item {
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding-top: 10px;
}

.list-group-item img,
.user-perfil__img,
.chat-container__message-avatar {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

::-webkit-scrollbar {
  background: #dee4e7;
}

::-webkit-scrollbar-thumb {
  background: #66b9fb;
}

::-webkit-scrollbar {
  width: 6px;
  height: 4px;
}

html {
  overflow-y: scroll;
  scrollbar-color: #66b9fb #fff;
  scrollbar-width: thin;
}

/*--------------------------------------------------------------
# Main
--------------------------------------------------------------*/
#main {
  margin-top: 26px;
  padding: 20px 30px;
  transition: all 0.3s;
}

@media (max-width: 1199px) {
  #main {
    padding: 20px;
  }
}

/*--------------------------------------------------------------
# Page Title
--------------------------------------------------------------*/
.pagetitle {
  margin-bottom: 10px;
}

.pagetitle h1 {
  margin-bottom: 0;
  font-weight: 600;
  color: #1a5166;
  font-size: 25px;
  font-weight: 700;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 99999;
  background: #4154f1;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 18px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #6776f4;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Override some default Bootstrap stylings
--------------------------------------------------------------*/
/* Dropdown menus */
.dropdown-menu {
  border-radius: 4px;
  padding: 10px 0;
  -webkit-animation-name: dropdown-animate;
  animation-name: dropdown-animate;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  border: 0;
  box-shadow: 0 5px 30px 0 rgba(82, 63, 105, 0.2);
}

.dropdown-menu .dropdown-header,
.dropdown-menu .dropdown-footer {
  text-align: center;
  font-size: 15px;
  padding: 10px 25px;
}

.dropdown-menu .dropdown-footer a {
  color: #444444;
}

.dropdown-menu .dropdown-footer a:hover {
  text-decoration: none;
  color: #6776f4;
}

.dropdown-menu .dropdown-divider {
  color: #a5c5fe;
  margin: 0;
}

.dropdown-menu .dropdown-item {
  font-size: 18px;
  padding: 10px 15px;
  transition: 0.3s;
}

.dropdown-menu .dropdown-item i {
  margin-right: 10px;
  font-size: 18px;
  line-height: 0;
}

.dropdown-menu .dropdown-item:hover {
  background-color: #E4E6E8;
}

@media (min-width: 768px) {
  .dropdown-menu-arrow::before {
    content: "";
    width: 13px;
    height: 13px;
    background: #fff;
    position: absolute;
    top: -7px;
    right: 20px;
    transform: rotate(45deg);
    border-top: 1px solid #eaedf1;
    border-left: 1px solid #eaedf1;
  }
}

@-webkit-keyframes dropdown-animate {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }

  0% {
    opacity: 0;
  }
}

@keyframes dropdown-animate {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }

  0% {
    opacity: 0;
  }
}

/* Light Backgrounds */
.bg-primary-light {
  background-color: #cfe2ff;
  border-color: #cfe2ff;
}

.bg-secondary-light {
  background-color: #e2e3e5;
  border-color: #e2e3e5;
}

.bg-success-light {
  background-color: #d1e7dd;
  border-color: #d1e7dd;
}

.bg-danger-light {
  background-color: #f8d7da;
  border-color: #f8d7da;
}

.bg-warning-light {
  background-color: #fff3cd;
  border-color: #fff3cd;
}

.bg-info-light {
  background-color: #cff4fc;
  border-color: #cff4fc;
}

.bg-dark-light {
  background-color: #d3d3d4;
  border-color: #d3d3d4;
}

/* Card */
.card {
  margin-bottom: 30px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
}

.card-fixed {
  position: fixed;
  width: 28%;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
screen and (max-width:767px) {
  .card.card-fixed {
    display: none;
  }
}

.card-header,
.card-footer {
  border-color: #ebeef4;
  background-color: #fff;
  color: #798eb3;
  padding: 15px;
}

.card-title {
  padding: 20px 0 15px 0;
  font-size: 18px;
  font-weight: 500;
  color: #000;
  font-family: "Poppins", sans-serif;
}

.card-title span {
  color: #899bbd;
  font-size: 18px;
  font-weight: 400;
}

.card-body {
  padding: 20px;
}

.card-img-overlay {
  background-color: rgba(255, 255, 255, 0.6);
}

.card-publication {
  transition: transform 0.5s ease-out;
}

.card-animation.card-publication {
  position: fixed;
  top: 10px;
  z-index: 100;
  box-sizing: unset;
  transform: translateY(0px);
  opacity: 1;
  animation: smoothScroll 1s forwards;
  border-radius: 0px;
  width: 30%;
  left: 700px;
  padding: 0px;
  margin-bottom: 0px;
}

.card-animation.card-publication .modal__btn-open {
  font-size: 18px;
}

.card-animation.card-publication .card-body {
  padding: 0px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .card-animation.card-publication {
    left: 286px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .card-animation.card-publication {
    left: 115px;
    width: 300px;
    min-width: 300px;
    max-width: 300px;
  }
}

@media (max-width:767px) {
  .card-animation.card-publication {
    left: 115px;
    width: 40px;
    min-width: 40px;
    max-width: 40px;
  }

  .card-animation.card-publication .modal__texto {
    display: none;
  }

  .card-animation.card-publication .modal__icon-movil {
    display: flex;
  }

  .card-animation.card-publication .modal__icon-movil i {
    width: 25px;
    height: 25px;
  }
}

@keyframes smoothScroll {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

/* Alerts */
.alert-heading {
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
}

/* Close Button */
.btn-close {
  background-size: 25%;
}

.btn-close:focus {
  outline: 0;
  box-shadow: none;
}

/* Accordion */
.accordion-item {
  border: 1px solid #ebeef4;
}

.accordion-button:focus {
  outline: 0;
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  color: #000;
  background-color: #f6f9ff;
}

.accordion-flush .accordion-button {
  padding: 15px 0;
  background: none;
  border: 0;
}

.accordion-flush .accordion-button:not(.collapsed) {
  box-shadow: none;
  color: #4154f1;
}

.accordion-flush .accordion-body {
  padding: 0 0 15px 0;
  color: #3e4f6f;
  font-size: 18px;
}

/* Breadcrumbs */
.breadcrumb {
  font-size: 18px;
  font-family: "Nunito", sans-serif;
  color: #899bbd;
  font-weight: 600;
}

.breadcrumb a {
  color: #899bbd;
  transition: 0.3s;
}

.breadcrumb a:hover {
  color: #51678f;
}

.breadcrumb .breadcrumb-item::before {
  color: #899bbd;
}

.breadcrumb .active {
  color: #51678f;
  font-weight: 600;
}

/* Bordered Tabs */
.nav-tabs-bordered {
  border-bottom: 2px solid #ebeef4;
}

.nav-tabs-bordered .nav-link {
  margin-bottom: -2px;
  border: none;
  color: #2c384e;
}

.nav-tabs-bordered .nav-link:hover,
.nav-tabs-bordered .nav-link:focus {
  color: #4154f1;
}

.nav-tabs-bordered .nav-link.active {
  background-color: #fff;
  color: #4154f1;
  border-bottom: 2px solid #4154f1;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.logo {
  line-height: 1;
}

@media (min-width: 1200px) {
  .logo {
    width: 280px;
  }
}

.logo img {
  max-height: 44px;
}

.logo span {
  font-size: 26px;
  font-weight: 700;
  color: #000;
  font-family: "Nunito", sans-serif;
}

.header {
  transition: all 0.5s;
  z-index: 100;
  height: 60px;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
  background-color: #fff;
  padding-left: 20px;
}

.header .toggle-sidebar-btn {
  font-size: 32px;
  padding-left: 10px;
  cursor: pointer;
  color: #000;
}

.header .search-bar {
  min-width: 360px;
  padding: 0 20px;
}

@media (max-width: 1199px) {
  .header .search-bar {
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    padding: 20px 20px 0px 20px;
    box-shadow: 0px 0px 15px 0px rgba(1, 41, 112, 0.1);
    background: white;
    z-index: 9999;
    transition: 0.3s;
    visibility: hidden;
    opacity: 0;
  }

  .header .search-bar-show {
    top: 60px;
    visibility: visible;
    opacity: 1;
  }
}

.header .search-form {
  width: 100%;
}

.header .search-form input {
  border: 0;
  font-size: 14px;
  color: #000;
  padding: 7px 38px 7px 8px;
  border-radius: 3px;
  border: 1px solid #d1f0ff;
  background-color: #d1f0ff;
  border-color: #a2c2e6;
  box-shadow: 0 0 3px 0 #66b9fb;
  transition: background-color .15s ease-in-out;
  width: 100%;
}

.header .search-form input:focus,
.header .search-form input:hover {
  outline: none;
  box-shadow: 0 0 4px 0 #007bdd;
  border: 1px solid #66b9fb;
}

.header .search-form button {
  border: 0;
  padding: 0;
  margin-left: -30px;
  background: none;
}

.header .search-form button i {
  color: #000;
}

.header .search-bar {
  position: relative
}

.header .list-unstyled {
  position: absolute;
  background: #fff;
  width: 100%;
  top: 30px;
}

.header .nt-item__img {
  width: 60px;
  border-radius: 10px;
  padding: 0px;
  margin-right: 10px;
  margin-top: -10px
}

.header .nt-item__description {
  display: flex;
  flex-direction: column;
}

.header .nt-item__description span:nth-child(1) {
  font-size: 18px;
}

.header .nt-item__description span:nth-child(2) {
  font-size: 15px;
}

.nav-item-users {
  display: none;
}

.nav-item-users img {
  width: 50px
}

.nav-item-users .dropdown-menu {
  padding: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
screen and (max-width:767px) {
  .header .search-bar {
    position: inherit
  }

  .nav-item-users,
  .show-all-contacts {
    display: block;
  }

  .header .list-unstyled {
    position: initial;
  }
}

/*--------------------------------------------------------------  
# Header Nav
--------------------------------------------------------------*/
.header-nav ul {
  list-style: none;
}

.header-nav>ul {
  margin: 0;
  padding: 0;
}

.header-nav .nav-icon {
  font-size: 22px;
  color: #000;
  margin-right: 25px;
  position: relative;
}

.header-nav .nav-profile {
  color: #000;
}

.header-nav .nav-profile img {
  width: 36px;
  max-width: 36px;
  height: 36px;
  max-height: 36px;
}

.header-nav .nav-profile span {
  font-size: 18px;
  font-weight: 600;
}

.header-nav .badge-number {
  position: absolute;
  inset: -2px -5px auto auto;
  font-weight: normal;
  font-size: 12px;
  padding: 3px 6px;
}

.header-nav .notifications {
  inset: 8px -15px auto auto !important;
}

.header-nav .notifications .notification-item {
  display: flex;
  align-items: center;
  padding: 15px 10px;
  transition: 0.3s;
}

.header-nav .notifications .notification-item i {
  margin: 0 20px 0 10px;
  font-size: 24px;
}

.header-nav .notifications .notification-item h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}

.header-nav .notifications .notification-item p {
  font-size: 13px;
  margin-bottom: 3px;
  color: #919191;
}

.header-nav .notifications .notification-item:hover {
  background-color: #f6f9ff;
}

.header-nav .messages {
  inset: 8px -15px auto auto !important;
}

.header-nav .messages .message-item {
  padding: 15px 10px;
  transition: 0.3s;
}

.header-nav .messages .message-item a {
  display: flex;
}

.header-nav .messages .message-item img {
  margin: 0 20px 0 10px;
  max-height: 40px;
}

.header-nav .messages .message-item h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #444444;
}

.header-nav .messages .message-item p {
  font-size: 13px;
  margin-bottom: 3px;
  color: #919191;
}

.header-nav .messages .message-item:hover {
  background-color: #f6f9ff;
}

.header-nav .profile {
  min-width: 240px;
  padding-bottom: 0;
  top: 8px !important;
}

.header-nav .profile .dropdown-header h6 {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 600;
  color: #444444;
}

.header-nav .profile .dropdown-header span {
  font-size: 14px;
}

.header-nav .profile .dropdown-item {
  font-size: 14px;
  padding: 10px 15px;
  transition: 0.3s;
}

.header-nav .profile .dropdown-item i {
  margin-right: 10px;
  font-size: 18px;
  line-height: 0;
}

.header-nav .profile .dropdown-item:hover {
  background-color: #f6f9ff;
}

/*--------------------------------------------------------------
# Sidebar
--------------------------------------------------------------*/
.sidebar {
  position: fixed;
  top: 60px;
  left: 0;
  bottom: 0;
  width: 300px;
  z-index: 98;
  transition: all 0.3s;
  padding: 20px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #aab7cf transparent;
  box-shadow: 0px 0px 20px rgba(1, 41, 112, 0.1);
  background-color: #fff;
}

@media (max-width: 1199px) {
  .sidebar {
    left: -300px;
  }
}

.sidebar::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #fff;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #aab7cf;
}

@media (min-width: 1200px) {

  #main,
  #footer {
    margin-left: 300px;
  }
}

@media (max-width: 1199px) {
  .toggle-sidebar .sidebar {
    left: 0;
  }
}

@media (min-width: 1200px) {

  .toggle-sidebar #main,
  .toggle-sidebar #footer {
    margin-left: 0;
  }

  .toggle-sidebar .sidebar {
    left: -300px;
  }
}

.sidebar-nav {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav .nav-item {
  margin-bottom: 5px;
}

.sidebar-nav .nav-heading {
  font-size: 11px;
  text-transform: uppercase;
  color: #899bbd;
  font-weight: 600;
  margin: 10px 0 5px 15px;
}

.sidebar-nav .nav-link {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  color: #000;
  transition: 0.3;
  background: #f6f9ff;
  padding: 10px 15px;
  border-radius: 4px;
}

.sidebar-nav .nav-link i {
  font-size: 18px;
  margin-right: 10px;
  color: #000;
}

.sidebar-nav .nav-link.collapsed {
  color: #000;
  background: #fff;
}

.sidebar-nav .nav-link.collapsed i {
  color: #899bbd;
}

.sidebar-nav .nav-link:hover {
  color: #4154f1;
  background: #f6f9ff;
}

.sidebar-nav .nav-link:hover i {
  color: #4154f1;
}

.sidebar-nav .nav-link .bi-chevron-down {
  margin-right: 0;
  transition: transform 0.2s ease-in-out;
}

.sidebar-nav .nav-link:not(.collapsed) .bi-chevron-down {
  transform: rotate(180deg);
}

.sidebar-nav .nav-content {
  padding: 5px 0 0 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav .nav-content a {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #000;
  transition: 0.3;
  padding: 10px 0 10px 40px;
  transition: 0.3s;
}

.sidebar-nav .nav-content a i {
  font-size: 6px;
  margin-right: 8px;
  line-height: 0;
  border-radius: 50%;
}

.sidebar-nav .nav-content a:hover,
.sidebar-nav .nav-content a.active {
  color: #4154f1;
}

.sidebar-nav .nav-content a.active i {
  background-color: #4154f1;
}

/*--------------------------------------------------------------
# Dashboard
--------------------------------------------------------------*/
/* Filter dropdown */
.dashboard .filter {
  position: absolute;
  right: 0px;
  top: 15px;
}

.dashboard .filter .icon {
  color: #006eff;
  padding-right: 20px;
  padding-bottom: 5px;
  transition: 0.3s;
  font-size: 16px;
}

.dashboard .filter .icon:hover,
.dashboard .filter .icon:focus {
  color: #006eff;
}

.dashboard .filter .dropdown-header {
  padding: 8px 15px;
}

.dashboard .filter .dropdown-header h6 {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #aab7cf;
  margin-bottom: 0;
  padding: 0;
}

.dashboard .filter .dropdown-item {
  padding: 8px 15px;
}

/* Info Cards */
.dashboard .info-card h6 {
  font-size: 28px;
  color: #000;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.dashboard .card-icon {
  font-size: 32px;
  line-height: 0;
  width: 64px;
  height: 64px;
  flex-shrink: 0;
  flex-grow: 0;
}

.dashboard .sales-card .card-icon {
  color: #4154f1;
  background: #f6f6fe;
}

.dashboard .revenue-card .card-icon {
  color: #2eca6a;
  background: #e0f8e9;
}

.dashboard .customers-card .card-icon {
  color: #ff771d;
  background: #ffecdf;
}

/* Activity */
.dashboard .activity {
  font-size: 14px;
}

.dashboard .activity .activity-item .activite-label {
  color: #888;
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;
  min-width: 64px;
}

.dashboard .activity .activity-item .activite-label::before {
  content: "";
  position: absolute;
  right: -11px;
  width: 4px;
  top: 0;
  bottom: 0;
  background-color: #eceefe;
}

.dashboard .activity .activity-item .activity-badge {
  margin-top: 3px;
  z-index: 1;
  font-size: 11px;
  line-height: 0;
  border-radius: 50%;
  flex-shrink: 0;
  border: 3px solid #fff;
  flex-grow: 0;
}

.dashboard .activity .activity-item .activity-content {
  padding-left: 10px;
  padding-bottom: 20px;
}

.dashboard .activity .activity-item:first-child .activite-label::before {
  top: 5px;
}

.dashboard .activity .activity-item:last-child .activity-content {
  padding-bottom: 0;
}

/* News & Updates */
.dashboard .news .post-item+.post-item {
  margin-top: 15px;
}

.news>.post-item.clearfix {
  display: flex;
  align-items: center;
  gap: 10px;
}

.dashboard .news img {
  width: 60px;
  height: 60px;
  float: left;
  border-radius: 50%;
}

.dashboard .news h4 {
  font-size: 15px;
  margin-left: 95px;
  font-weight: bold;
  margin-bottom: 5px;
}

.dashboard .news h4 a {
  color: #000;
  transition: 0.3s;
}

.dashboard .news h4 a:hover {
  color: #4154f1;
}

.dashboard .news p {
  font-size: 14px;
  color: #777777;
  margin-left: 95px;
}

/* Recent Sales */
.dashboard .recent-sales {
  font-size: 14px;
}

.dashboard .recent-sales .table thead {
  background: #f6f6fe;
}

.dashboard .recent-sales .table thead th {
  border: 0;
}

.dashboard .recent-sales .dataTable-top {
  padding: 0 0 10px 0;
}

.dashboard .recent-sales .dataTable-bottom {
  padding: 10px 0 0 0;
}

/* Top Selling */
.dashboard .top-selling {
  font-size: 14px;
}

.dashboard .top-selling .table thead {
  background: #f6f6fe;
}

.dashboard .top-selling .table thead th {
  border: 0;
}

.dashboard .top-selling .table tbody td {
  vertical-align: middle;
}

.dashboard .top-selling img {
  border-radius: 5px;
  max-width: 60px;
}

/*--------------------------------------------------------------
# Icons list page
--------------------------------------------------------------*/
.iconslist {
  display: grid;
  max-width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 1.25rem;
  padding-top: 15px;
}

.iconslist .icon {
  background-color: #fff;
  border-radius: 0.25rem;
  text-align: center;
  color: #000;
  padding: 15px 0;
}

.iconslist i {
  margin: 0.25rem;
  font-size: 2.5rem;
}

.iconslist .label {
  font-family: var(--bs-font-monospace);
  display: inline-block;
  width: 100%;
  overflow: hidden;
  padding: 0.25rem;
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #666;
}

/*--------------------------------------------------------------
# Profie Page
--------------------------------------------------------------*/
.profile-card {
  gap: 5px;
}

.profile .profile-card img {
  height: 200px;
  width: 200px;
  max-width: 200px;
  max-height: 200px;
}

.profile .profile-card h2 {
  font-size: 24px;
  font-weight: 700;
  color: #2c384e;
  margin: 10px 0 0 0;
}

.profile .profile-card h3 {
  font-size: 18px;
}

.social-links {
  margin-bottom: 10px;
}

.profile .profile-card .social-links a {
  font-size: 20px;
  display: inline-block;
  color: rgba(1, 41, 112, 0.5);
  line-height: 0;
  margin-right: 10px;
  transition: 0.3s;
}

.profile .profile-card .social-links a:hover {
  color: #012970;
}

.profile .profile-overview .row {
  margin-bottom: 20px;
  font-size: 15px;
}

.profile .profile-overview .card-title {
  color: #000;
}

.profile .profile-overview .label {
  font-weight: 600;
  color: rgba(1, 41, 112, 0.6);
}

.profile .profile-edit label {
  font-weight: 600;
  color: rgba(1, 41, 112, 0.6);
}

.profile .profile-edit img {
  max-width: 120px;
}

.profile .col-md-auto {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

/*--------------------------------------------------------------
# F.A.Q Page
--------------------------------------------------------------*/
.faq .basic h6 {
  font-size: 18px;
  font-weight: 600;
  color: #4154f1;
}

.faq .basic p {
  color: #6980aa;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info-box {
  padding: 28px 30px;
}

.contact .info-box i {
  font-size: 38px;
  line-height: 0;
  color: #4154f1;
}

.contact .info-box h3 {
  font-size: 20px;
  color: #000;
  font-weight: 700;
  margin: 20px 0 10px 0;
}

.contact .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  border-radius: 0;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: #4154f1;
}

.contact .php-email-form input {
  padding: 10px 15px;
}

.contact .php-email-form textarea {
  padding: 12px 15px;
}

.contact .php-email-form button[type=submit] {
  background: #4154f1;
  border: 0;
  padding: 10px 30px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}

.contact .php-email-form button[type=submit]:hover {
  background: #5969f3;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Error 404
--------------------------------------------------------------*/
.error-404 {
  padding: 30px;
}

.error-404 h1 {
  font-size: 180px;
  font-weight: 700;
  color: #4154f1;
  margin-bottom: 0;
  line-height: 150px;
}

.error-404 h2 {
  font-size: 24px;
  font-weight: 700;
  color: #000;
  margin-bottom: 30px;
}

.error-404 .btn {
  background: #51678f;
  color: #fff;
  padding: 8px 30px;
}

.error-404 .btn:hover {
  background: #3e4f6f;
}

@media (min-width: 992px) {
  .error-404 img {
    max-width: 50%;
  }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer {
  padding: 20px 0;
  font-size: 14px;
  transition: all 0.3s;
  border-top: 1px solid #cddfff;
  background-color: #fff;
}

.footer-no-index {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.footer .copyright {
  text-align: center;
  color: #000;
}

.footer .credits {
  padding-top: 5px;
  text-align: center;
  font-size: 13px;
  color: #000;
}

/*--------------------------------------------------------------
# like
--------------------------------------------------------------*/
.btn-wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: end;
}

.btn-like,
.btn-dislike,
.btn__comments {
  cursor: pointer;
  font-size: 18px;
  color: #000;
}


.like {
  color: #006eff;
}

.btn-like:hover {
  color: #006eff;
}

.dislike {
  color: red;
}

.btn-dislike:hover {
  color: red;
}

@media (max-width:767px) {
  .btn-wrapper {
    margin-top: 10px;
  }
}

/*--------------------------------------------------------------
# Autocompletado
--------------------------------------------------------------*/
.ui-menu-item {
  height: 70px;
  border: 1px solid #ffffff;
}

.label {
  background: #ffffff;
  color: #0b0b0b;
  text-align: left;
}

.ui-menu-item .ui-menu-item-wrapper.ui-state-active {
  border-color: #ffffff;
  background: #ffffff !important;
}

/*--------------------------------------------------------------
# File Comentario
--------------------------------------------------------------*/
.file-select {
  position: relative;
  display: inline-block;
}

.file-select::before {
  background-color: #17a2b8;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  content: 'Subir Imagen';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.file-select input[type="file"] {
  opacity: 0;
  width: 120px;
  height: 32px;
  display: inline-block;
}

#src-file1::before {
  content: 'Subir Imagen';
}

.margenImagenComment {
  width: 100px;
  height: 100px;
  border-radius: 10%;
  margin-bottom: 20px;
}

/*--------------------------------------------------------------
# Mostrar Contactos Followers
--------------------------------------------------------------*/
.show-contacts {
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
  margin-top: 10px;
}

.show-contact__user-name {
  font-size: 20px;
}

.show-contact__link {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 5px;
}

.show-contact__link>a img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.show-contact__link:hover {
  background-color: #E4E6E8;
}

.show-contact__info {
  display: flex;
  flex-direction: column;
}

.show-contact__online {
  border: 3px solid #06ac53;
  border-radius: 50%;
  padding: 3px;
}

.show-contact__off-online {
  border: 3px solid #e61b1b;
  border-radius: 50%;
  padding: 3px;
}

.show-contact__new-messages {
  font-weight: bold;
  color: #1368cf;
  display: flex;
  gap: 10px;
  cursor: pointer;
}

.show-contact__count-messages {
  display: flex;
  justify-content: center;
  gap: 2px;
}

.show-contact__count-messages,
.bi.bi-envelope-fill {
  margin-top: 0.5px;
}

.show-contact__chat {
  cursor: pointer;
  font-weight: 900;
  color: #000;
}

.show-contact__chat:hover {
  color: #006eff
}

/*--------------------------------------------------------------
# Estructura del chat
--------------------------------------------------------------*/
.chat-container {
  display: flex;
  flex-direction: column;
  height: 500px;
  max-width: 100%;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f8f9fa;
  overflow: hidden;
}

.chat-container__box {
  flex: 1;
  overflow-y: auto;
  padding: 15px;
}

.chat-container__message {
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;
}

.chat-container__message--received {
  justify-content: end;
}

.chat-container__message--sent {
  justify-content: flex-start;
}

.chat-container__message-content {
  max-width: 60%;
  padding: 10px;
  border-radius: 10px;
  background-color: #e1ffc7;
  border: 1px solid #c3e3d8;
}

.chat-container__message--sent .chat-container__message-content {
  background-color: #d1f0ff;
  border-color: #a2c2e6;
}

.chat-container__input,
.sendMessage {
  margin-left: 0px;
}

.chat-container__input {
  border-top: 1px solid #ddd;
}

.chat__input {
  padding: 10px;
  border-radius: 5px;
  font-size: 16px !important;
}

.chat-container__emoji {
  cursor: pointer;
  font-size: 24px;
  margin: 5px;
}

.chat-container__modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 996;
}

.chat-container__modal .chat-container__close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}

.chat-container__message--received,
.chat-container__message--sent {
  display: flex;
  align-items: center;
  gap: 10px;
}

.chat-container__message--sent a {
  order: 1;
}

.chat-container__message--sent .chat-container__message-content {
  order: 2;
}

.chat-container__message--received a {
  order: 2;
}

.chat-container__message--received .chat-container__message-content {
  order: 1;
}

.chat-container__message-content {
  display: flex;
  flex-direction: column;
}

.chat-container__message--received .chat-container__message-content .chat-user-bold {
  order: 2;
}

.chat-container__message--received .chat-container__message-content .chat-text {
  order: 1;
  text-align: right;
}

.chat-container__message--received .chat-container__message-content {
  flex-direction: column-reverse;
  align-items: end;
}

.chat-user-bold {
  font-weight: 600;
}

/*--------------------------------------------------------------
# Autor
--------------------------------------------------------------*/
.credits__autor {
  display: flex;
  justify-content: center;
  color: #000;
  gap: 5px;
}

.credits__autor:hover {
  color: #006eff
}

/*--------------------------------------------------------------
# Juego Memory
--------------------------------------------------------------*/
.memory-game__board {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 15px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.memory-game__card {
  background-color: #1e3a8a;
  border: 2px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
  max-height: 150px;
  min-height: 150px;
  position: relative;
  cursor: pointer;
  transition: transform 0.3s;
  transform-style: preserve-3d;
  perspective: 1000px;
  font-size: 24px;
}

.memory-game__card img {
  width: 80%;
  height: auto;
  display: none;
}

.memory-game__card--flipped img {
  display: block;
}

.memory-game__card--matched {
  background-color: #dff0d8;
  border-color: #3c763d;
  pointer-events: none;
}

.memory-game__card::after {
  content: "?";
  font-size: 10rem;
  color: white;
  font-weight: bold;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  backface-visibility: hidden;
}

.memory-game__card::before {
  position: absolute;
  backface-visibility: hidden;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  color: #333;
  transform: rotateY(180deg);
  border-radius: 10px;
}

.memory-game__card--flipped {
  background-color: #fff;
  transform: rotateY(180deg);
}

.memory-game__card--flipped::after {
  display: none;
}

.memory-game__card--matched::after {
  display: none;
}

.memory-game__card--flipped::before,
.memory-game__card--matched::before {
  display: block;
}

.memory-game__card i {
  width: 100px;
  height: 110px;
}

.card-memory:hover {
  transform: scale(1.05);
}

.card-cntn-memory {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding: 20px;
  text-align: center;
  height: 386px;
  max-height: 386px;
  min-height: 386px;
}

.card-memory {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1e3a8a;
  padding: 10px;
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease
}

.card-cntn-memory i {
  width: 66px;
  height: 70px;
  transition: transform 0.3s ease, background-color 0.3s ease;
  animation: pulse 1.5s infinite ease-in-out;
}

@keyframes pulse {

  0%,
  100% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(1.1);
    opacity: 0.8;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .card-cntn-memory {
    grid-template-columns: repeat(2, 1fr);
    height: 540px;
    max-height: 540px;
    min-height: 540px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .memory-game__board {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (max-width:767px) {
  .card-cntn-memory {
    padding: 10px;
  }

  .card-memory {
    padding: 5px;
  }

  .memory-game__board {
    grid-template-columns: repeat(3, 1fr);
  }

  .memory-game__card::after {
    font-size: 4rem;
  }

  .memory-game__card i {
    width: 66px;
    height: 73px;
  }
}

/*--------------------------------------------------------------
# Juego Trivia
--------------------------------------------------------------*/

.quiz-container {
  padding: 20px 30px;
  animation: fadeIn 1s ease-out;
}

.quiz-header h2 {
  margin: 0;
  font-size: 1.8rem;
  text-align: center;
}

.quiz-question {
  margin-bottom: 15px;
  font-size: 1.2rem;
}

.quiz-options {
  list-style: none;
  padding: 0;
}

.quiz-options li {
  margin: 10px 0;
  padding: 10px 15px;
  border-radius: 8px;
  cursor: pointer;
  background-color: #d1f0ff;
  border-color: #a2c2e6;
  box-shadow: 0 0 3px 0 #66b9fb;
  transition: background-color .15s ease-in-out;
}

.quiz-options li.correct {
  background: #10b981;
}

.quiz-options li.wrong {
  background: #ef4444;
}

.card-game .btn {
  display: inline-block;
  margin-top: 20px;
  color: white;
  padding: 10px 20px;
  border-radius: 8px;
  text-align: center;
  text-decoration: none;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s ease;
}

.card-game .card-body {
  height: 370px;
  max-height: 370px;
  min-height: 370px;
}

.card-game .card-text {
  height: 205px;
  max-height: 205px;
  min-height: 205px;
  margin-bottom: 0px;
}

.card-game .btn:hover {
  background: #0a58ca;
}

.hidden {
  display: none;
}

.quiz-result {
  text-align: center;
  font-size: 3.5rem;
  margin-top: 20px;
  color: green;
}

.card-cntn-trivia {
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 386px;
  max-height: 386px;
  min-height: 386px;
}

.answer-option {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  border-radius: 8px;
  background-color: #d1f0ff;
  border: 2px solid #a2c2e6;
  box-shadow: 0 0 5px 0 #66b9fb;
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
}

/* Icono de check (verde) */
.answer-option i.bi-check-circle {
  color: green;
  animation: pulseCheck 1s infinite;
}

/* Icono de x (rojo) */
.answer-option i.bi-x-circle {
  color: red;
  animation: pulseX 1s infinite;
}

/* Efecto cuando el cursor pasa sobre la opción */
.answer-option:hover {
  transform: scale(1.05);
}

/* Animación para el icono de check (verde) */
@keyframes pulseCheck {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
    /* Se agranda */
  }

  100% {
    transform: scale(1);
  }
}

/* Animación para el icono de x (rojo) */
@keyframes pulseX {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
    /* Se agranda */
  }

  100% {
    transform: scale(1);
  }
}

/* Animación para la aparición de la página */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .card-cntn-trivia {
    height: 540px;
    max-height: 540px;
    min-height: 540px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px),
screen and (min-width: 768px) and (max-width: 991px),
screen and (max-width:767px) {
  .quiz-header h2 {
    margin-bottom: 30px;
  }
}

@media (max-width:767px) {

  .quiz-result {
    font-size: 1.5rem;
  }

  .quiz-header h2 {
    font-size: 1.3em;
  }

  .quiz-question {
    font-size: 1.1em;
  }
}

/*--------------------------------------------------------------
# Juego Ahorcado (hanged)
--------------------------------------------------------------*/

.game-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.game-container {
  background-color: #fff;
  padding: 40px;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
  text-align: center;
  animation: fadeIn 1s ease-out;
  width: 100%;
}

.title-games {
  margin-bottom: 20px;
  font-size: 2rem;
  color: #333;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  text-align: center;
}

/* Estilo para la palabra oculta */
.word-display {
  font-weight: bold;
  color: #444;
  text-transform: uppercase;
  font-size: 30px;
  margin: 10px 0;
  margin-bottom: 30px;
  letter-spacing: 6px;
}

.letters {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 12px;
  margin-top: 20px;
}

.letter {
  width: 55px;
  height: 55px;
  background-color: #f0f0f0;
  border: 2px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 5px 10px;
  margin: 3px;
  font-size: 20px;
  transition: background-color 0.3s;
  animation: pulse 1.5s infinite ease-in-out;
}

.letter:active {
  transform: scale(0.9);
}

.letters-p {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
}

.letter-p {
  width: 40px;
  height: 40px;
  background-color: #f0f0f0;
  border: 2px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 5px 10px;
  font-size: 16px;
  transition: background-color 0.3s;
  animation: pulse 1.5s infinite ease-in-out;
}

.letter.disabled {
  background-color: #ccc;
  cursor: not-allowed;
  transform: scale(1);
}

.result {
  font-size: 1.5rem;
  margin-top: 30px;
  color: #333;
  font-weight: bold;
  transition: opacity 0.3s;
}

/* Barra de progreso */
.progress-container {
  width: 100%;
  height: 20px;
  background-color: #e0e0e0;
  margin-top: 30px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.1);
}

.progress-bar {
  height: 100%;
  background-color: #28a745;
  width: 0%;
  transition: width 0.3s ease-in-out;
}

/* Animación para la aparición de la página */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.card-cntn-hangman {
  height: 386px;
  max-height: 386px;
  min-height: 386px;
}

.hangman-thumbnail {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  align-items: center;
}

.progress-bar {
  height: 100%;
  background-color: #28a745;
  border-radius: 10px;
}

.letter-item {
  opacity: 0;
  animation: appear 1s forwards;
}

.letter-item:nth-child(1) {
  animation-delay: 0.2s;
}

.letter-item:nth-child(2) {
  animation-delay: 0.4s;
}

.letter-item:nth-child(3) {
  animation-delay: 0.6s;
}

.letter-item:nth-child(4) {
  animation-delay: 0.8s;
}

.letter-item:nth-child(5) {
  animation-delay: 1s;
}

.letter-item:nth-child(6) {
  animation-delay: 1.2s;
}

.letter-item:nth-child(7) {
  animation-delay: 1.4s;
}

.letter-item:nth-child(8) {
  animation-delay: 1.6s;
}

.letter-item:nth-child(9) {
  animation-delay: 1.8s;
}

/* Definimos la animación "appear" */
@keyframes appear {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Estilo para las letras disponibles */
.letters {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
}

.letter:hover {
  background-color: #ddd;
  transform: scale(1.1);
}

/* Animación de pulsar las letras */
@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
    /* Se agranda */
  }

  100% {
    transform: scale(1);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .card-cntn-hangman {
    height: 540px;
    max-height: 540px;
    min-height: 540px;
  }

  .letters-p {
    grid-template-columns: repeat(4, 1fr);
  }
}

/* Diseño responsivo */
@media (max-width: 768px) {

  .card-cntn-hangman {
    height: 440px;
    max-height: 440px;
    min-height: 440px;
  }

  .letters-p {
    grid-template-columns: repeat(4, 1fr);
  }

  .word-display {
    padding-left: 30px;
  }

  .game-container {
    padding: 20px;
    max-width: 100%;
  }

  .word-display {
    font-size: 2.5rem;
  }

  .letter {
    width: 45px;
    height: 45px;
    font-size: 1.2rem;
  }

  .reset-button {
    padding: 12px 25px;
  }

  .hint-button {
    padding: 12px 25px;
  }
  
  .card.info-card.feature-card.h-90 {
    margin-bottom: 0px;
  }
}

@media (max-width: 480px) {
  .word-display {
    font-size: 2rem;
  }

  .letter {
    width: 40px;
    height: 40px;
    font-size: 1rem;
  }

  .reset-button {
    padding: 10px 20px;
  }

  .hint-button {
    padding: 10px 20px;
  }
}

/*--------------------------------------------------------------
# Modal
--------------------------------------------------------------*/
.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal__content {
  background: #eaf5fd;
  padding: 20px;
  border-radius: 5px;
  width: 80%;
  max-width: 600px;
  transform: translateY(-50px);
  transition: transform 0.3s ease, opacity 0.3s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translateY(-50px);
  border: 1px solid #66b9fb !important;
  box-shadow: 0 0 15px 0 #007bdd !important;
}

.modal--active .modal__content {
  transform: translate(-50%, -50%) translateY(0);
  opacity: 1;
}

.modal__header,
.modal__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.modal__header h5 {
  width: 100%;
  text-align: center;
  color: #1a5166;
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 0px;
}

.modal__close {
  background: none;
  border: none;
  font-size: 40px;
  cursor: pointer;
}

.modal__image-upload {
  display: flex;
  font-weight: 600;
  font-size: 20px;
}

.modal__image-upload-cntn {
  margin-right: 8px;
  font-size: 16px;
}

.modal__image-upload input[type="file"] {
  display: none;
}

.modal__image-preview {
  margin-top: 0px !important;
  justify-content: center;
  display: flex;
  display: none;
}

.modal__emoji-picker {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.modal__emoji-picker__emoji {
  font-size: 24px;
  cursor: pointer;
  transition: background-color .15s ease-in-out;
}

.modal__form-actions {
  display: grid;
  grid-template-columns: 12% 86%;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.modal__icon {
  width: 30px;
  height: 30px;
}

.modal__form-comments-edit .modal__group {
  margin-top: 20px;
  margin-bottom: 20px;
}

.modal__form-actions-commnets .form__emoji-picker {
  margin-top: 20px;
}

.modal__image-wrapper,
.modal__edit-image-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 21px;
  width: 100%;
}

.modal__image-preview-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  border: 1px solid #ced4da;
}

.modal__image-wrapper a {
  height: 160px;
  display: flex;
  align-items: center;
}

.modal__image-preview-item img {
  width: 130px;
  max-width: 130px;
  max-height: 160px;
}

.modal__image-preview-item a {
  margin-bottom: 10px;
  margin-top: 10px;
}

.modal__image-actions {
  width: 100%;
  display: flex;
  text-align: center;
}

.modal__image-actions .edit-image-btn:nth-child(1) {
  border-right: 1px solid #ced4da;
}

.modal__btn-open {
  color: #1a5166;
  font-size: 25px;
  font-weight: 700;
}

.modal__publication-textarea {
  padding: 20px;
}

.modal__image-actions .edit-image-btn,
.modal__image-actions .delete-image-btn {
  width: 100%;
  cursor: pointer;
  padding: 5px;
  border-top: 1px solid #ced4da;
}

.modal__actions-icon {
  height: 25px;
  width: 25px;
}

.modal__texto {
  display: flex;
}

.modal__icon-movil {
  display: none;
}

.modal .emojis-wrapper {
  cursor: pointer;
  font-size: 24px;
}

.modal .form__emoji-picker {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.modal .form__emoji-picker .form__emoji {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width:767px) {
  .modal__header h5 {
    text-align: left;
    font-size: 20px;
  }

  .modal__publication-textarea {
    padding: 10px;
  }

  .modal__texto,
  .modal__publication-textarea {
    font-size: 15px !important;
    text-align: center;
  }

  .modal__form-actions {
    grid-template-columns: 24% 72%;
  }

  .modal__image-upload {
    flex-direction: column;
    font-size: 15px;
  }

  .modal__image-preview img {
    max-height: 140px;
    min-height: 140px;
  }

  .modal .form__emoji-picker {
    grid-template-columns: repeat(5, 1fr);
  }

  .modal i,
  .emojis-wrapper-grid-large i {
    width: 35px;
    height: 35px;
  }
}

@media only screen and (min-width: 311px) and (max-width: 616px) {

  .modal__image-wrapper,
  .modal__edit-image-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width:310px) {

  .modal__image-wrapper,
  .modal__edit-image-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}

/*--------------------------------------------------------------
# Slick 
--------------------------------------------------------------*/
.slick__image {
  position: relative;
}

.slick-slide img {
  margin: 0 auto;
}

.slick__contn {
  width: 100%;
}

.slick__thumbnails {
  display: flex;
  margin-top: 20px;
  overflow-x: auto;
}

.thumbnail {
  margin: 0 5px;
}

.thumbnail img {
  max-width: 80px;
  min-width: 80px;
  min-height: 80px;
  max-height: 80px;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.2s;
  box-shadow: 0 0 4px 0 #007bdd;
  border: 1px solid #66b9fb;
}

.slick-slide.slick-current {
  display: flex;
  align-items: center;
  justify-content: center;
}

.slick-prev:before,
.slick-next:before {
  color: #66b9fb !important
}

.slick-prev {
  left: 0px !important;
}

.slick-next {
  right: 0px !important;
}

.slick-prev:before,
.slick-next:before {
  font-size: 50px !important;
}

.slick-prev,
.slick-next {
  z-index: 97 !important;
  width: 50px !important;
  height: 50px !important;
}

@media only screen and (min-width: 992px) and (max-width: 1200px),
screen and (min-width: 768px) and (max-width: 991px),
screen and (max-width:767px) {
  .slick__thumbnails {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

@media (max-width:767px) {

  .slick-prev:before,
  .slick-next:before {
    font-size: 30px !important;
  }

  .slick__image img {
    height: 280px;
    max-height: 280px;
  }
}

@media (max-width:310px) {
  .slick__image img {
    height: 100px;
    max-height: 100px;
  }
}

/*--------------------------------------------------------------
# Comentarios
--------------------------------------------------------------*/
.wrapper-comments {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f8f9fa;
  overflow: hidden;
  padding: 0px;
  margin-top: 20px;
  border-color: #a2c2e6;
  box-shadow: 0 0 3px 0 #66b9fb;
  transition: background-color .15s ease-in-out;
}

.comments__btns {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
}

.comments__btns a {
  padding: 5px 5px 0px 5px;
  width: 100%;
  text-align: center;
  font-size: 14px;
  border: 1px solid #ced4da;
}

.comments__btns i {
  padding-bottom: 0px;
  height: 25px;
  width: 25px;
}

.comments__card {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  padding: 10px;
}

.comments__card:nth-child(1) {
  margin-top: 20px;
}

.comments__card:hover {
  background: #E4E6E8;
}

.comments__description {
  display: flex;
  align-items: center;
  width: 100%
}

.comments__body {
  width: 100%;
  padding: 5px;
}

.comments__body p {
  margin-bottom: 0px;
}

.comment__btns-action {
  width: 22%;
}

@media (max-width:767px) {
  .comments__card .rounded-circle {
    width: 35px;
    height: 35px;
  }
}

/*--------------------------------------------------------------
# Formulario Comentarios
--------------------------------------------------------------*/
.form__comments {
  background-color: #ffffff;
}

.form__comments .input-group {
  gap: 10px
}

.form__collapse {
  cursor: pointer;
  font-weight: 600;
  font-size: 18px;
  color: #000000;
  text-transform: capitalize;
  padding-bottom: 10px;
}

.form__collapse:hover,
.btn__comments:hover {
  color: #006eff;
}

.btn__comments {
  width: 160px;
}

.form__send-icon {
  width: 30px;
  height: 30px;
}

.form__user-perfil,
.form__user-perfil .btn.btn-success,
.form__user-perfil .btn.btn-danger {
  width: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1200px),
screen and (min-width: 768px) and (max-width: 991px),
screen and (max-width:767px) {

  .comment__btns-action,
  .btn__comments {
    width: 100%;
  }

  .col.col-lg-2.comment__btns-action {
    flex: unset;
  }
}

/*--------------------------------------------------------------
# COMUNES
--------------------------------------------------------------*/
.emojis-wrapper-grid-large .form__emoji-picker {
  padding: 10px 20px 20px 20px;
}

.form__comments-group,
.chat-container__input {
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 10px;
  background-color: #ffffff;
}

.form__comments .form__comentario-input {
  width: 100% !important;
}

.chat__emojis-toggle,
.sendMessage,
.form__emojis-toggle,
.form__comments .button {
  width: 10%;
}

.form__comentario-input,
.chat__input {
  font-size: 15px !important;
}

input:focus {
  outline: none !important;
}

input:focus-visible {
  border-color: #a2c2e6 !important;
  box-shadow: 0 0 3px 0 #66b9fb !important;
}

@media (max-width:767px) {
  .emojis-wrapper-grid-large .form__emoji-picker {
    text-align: center;
    padding: 0px;
  }

  .chat__emojis-toggle,
  .sendMessage,
  .form__emojis-toggle,
  .form__comments .button {
    width: 20%;
  }

  .form__comentario-input,
  .chat__input {
    font-size: 15px !important;
  }
}

/*--------------------------------------------------------------
# Avatar User Perfil
--------------------------------------------------------------*/
.user-perfil__img-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-direction: column;
  width: 200px;
}

.user-perfil__img {
  height: 100px;
  width: 100px;
}

.user-perfil__btns-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
}

.user-perfil__btn {
  width: 100%;
  border: 1px solid #ced4da;
  display: flex;
  justify-content: center;
  padding: 5px;
}

.user-perfil__icon {
  width: 25px;
  height: 25px;
}

/*--------------------------------------------------------------
# Conoce la Plataforma
--------------------------------------------------------------*/
.intro-board {
  padding: 0px 30px 30px 30px;
}

.card__info {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
}

.title__intro {
  font-size: 30px;
  font-weight: 600;
  color: #000;
  padding-top: 30px;
}

.credit-text {
  margin-bottom: 0.5rem;
}

.feature-card {
  border: 1px solid #a2c2e6;
  background-color: #e9eef1;
  box-shadow: 0 0 3px 0 #66b9fb;
  transition: all 0.15s ease-in-out;
}

.card-header h3,
.card-header i {
  color: #2c8cd7;
}

.card-body-info {
  flex-grow: 1;
  padding: 20px;
}